<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Eliminar ensayo</v-card-title>

        <v-card-text>
          ¿Desea eliminar el ensayo {{ensayo.nombre}} ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="gray darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="red darken-1"
            :loading="loading"
            text
            @click="deleteE()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Service from '../../services/apis';

export default {
  props: {
    Father: Object,
  },
  data() {
    return {
      dialog: false,
      ensayo: {
        nombre: '',
      },
      item: null,
      loading: false,
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.item = item;
    },
    close() {
      this.dialog = false;
    },
    async deleteE() {
      // this.Father.deleteEnsayo();
      this.loading = true;
      const deletedTest = await Service.apiToken('POST', 'delete-test', this.$ls.storage.token, this.item);
      if (deletedTest) {
        this.Father.init();
      }
      this.item = null;
      this.loading = false;
      this.close();
    },
  },
};
</script>
